import { Checkbox, TextField } from "@material-ui/core";
import styled from "styled-components";

export const FormContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 30px 0;
`;

export const TextInput = styled(TextField)`
    margin: 10px 0;
    width: 400px;
`;

export const CheckInput = styled(Checkbox)`
    margin: 0 10px;
`;

export const Section = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
`;

export const Line = styled.div`
    margin: 10px 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid grey;
`;
